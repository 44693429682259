/* common */
.loading {
    font-size: 84px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    text-align: center;
    height: 100%;
}

.loading span {
    display: inline-block;
    /*margin: 0 -.05em;*/
}

.loading02 span {
    animation: loading02 1.2s infinite alternate;
}

.loading02 span:nth-child(2) {
    animation-delay: 0.2s;
}

.loading02 span:nth-child(3) {
    animation-delay: 0.4s;
}

.loading02 span:nth-child(4) {
    animation-delay: 0.6s;
}

.loading02 span:nth-child(5) {
    animation-delay: 0.8s;
}

.loading02 span:nth-child(6) {
    animation-delay: 1s;
}

.loading02 span:nth-child(7) {
    animation-delay: 1.2s;
}

.loading02 span:nth-child(8) {
    animation-delay: 1.4s;
}

@keyframes loading02 {
    0% {
        filter: blur(0);
        opacity: 1;
    }
    100% {
        filter: blur(1px);
        opacity: .5;
    }
}
