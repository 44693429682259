/* Styles de base */


#privacy-policy header {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 20px 0;
}

#privacy-policy header h1 {
    font-size: 24px;
}

#privacy-policy header p {
    font-size: 14px;
}

#privacy-policy section {
    background-color: #fff;
    padding: 20px;
    margin: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#privacy-policy section h2 {
    font-size: 20px;
    margin-top: 0;
    color: #333;
}

#privacy-policy ul {
    list-style-type: disc;
    margin-left: 20px;
}

#privacy-policy a {
    color: #007BFF;
    text-decoration: none;
}

#privacy-policy a:hover {
    text-decoration: underline;
}
